import flushPromises from 'flush-promises';
import { createLocalVue, shallowMount } from '@vue/test-utils';
import ProductWithFile from './ProductWithFile';
import Vuex from 'vuex';
import courseService from '@/sparkmembers/services/course';
import lessonService from '@/sparkmembers/services/lesson';
import mediaService from '@/sparkmembers/services/media';
import ToastHelper from '@/shared/helpers/toast';

jest.mock('@/router');
jest.mock('@/store');
jest.mock('@/sparkmembers/services/course');
jest.mock('@/sparkmembers/services/lesson');
jest.mock('@/sparkmembers/services/media');
jest.mock('@/services/analytics');
jest.mock('@/shared/helpers/toast');

const localVue = createLocalVue();
localVue.use(Vuex);

const stubs = {
  HsGroup: true,
  HsTextEditor: true,
  HsForm: true,
  HsIcon: true,
  HsInput: true,
  HsFormInvalidFeedback: true,
  HsButton: {
    template: '<div class="hs-button-stub"></div>',
    props: ['disabled'],
  },
  LessonReleaseLimitationCard: true,
};

const store = {
  modules: {
    school: {
      namespaced: true,
      state: {
        selectedSchool: {},
      },
      getters: {
        getDomain: () => [],
      },
    },
    auth: {
      namespaced: true,
      state: {
        loggedUser: { id: 1, title: 'My Title', niche: null, subtitle: null },
      },
      actions: {
        updateProduct: jest.fn(),
      },
    },
    product: {
      namespaced: true,
      state: {
        selectedProduct: { id: 1, title: 'My Title', niche: null, subtitle: null },
      },
      actions: {
        updateProduct: jest.fn(),
      },
    },
    course: {
      namespaced: true,
      state: {
        selectedCourse: { id: 1, name: 'My Title' },
      },
      actions: {
        updateCourse: jest.fn(),
      },
    },
  },
};

function mountWrapper(localVue, stubs, store, propsData) {
  return shallowMount(ProductWithFile, {
    localVue,
    stubs,
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
      $router: {
        push: jest.fn(),
      },
      $route: {
        params: {
          contentId: 1,
        },
      },
      $store: new Vuex.Store(store),
    },
    propsData,
  });
}

describe('Product With File Content', () => {
  let wrapper;

  beforeEach(() => {
    jest.spyOn(courseService, 'getCourseContent').mockResolvedValue({
      data: {
        id: 1,
        lesson: {
          id: 1,
          title: '',
          description: '',
          available: false,
          section_id: 1,
          media: null,
          contents: [],
        },
      },
    });

    jest.spyOn(lessonService, 'update').mockResolvedValue({
      data: { id: 1 },
    });

    jest.spyOn(mediaService, 'create').mockResolvedValue({
      data: { id: 1 },
    });

    wrapper = mountWrapper(localVue, stubs, store, {
      type: 'file',
      mediaTypes: [
        {
          id: 'file',
          title: 'title',
          icon: 'book-alt',
          active: false,
          supportedFiles: [],
        },
      ],
    });
  });

  it('should render', async () => {
    expect.assertions(1);
    await flushPromises();
    expect(wrapper.vm.content.id).toBe(1);
  });

  it('should create new media', async () => {
    expect.assertions(1);
    wrapper.vm.onMainMediaSave({});
    await flushPromises();
    expect(wrapper.vm.media.id).toBe(1);
  });

  it('should save details', async () => {
    expect.assertions(1);
    wrapper.vm.onDetailsSave({
      id: 1,
      title: 'newtitle',
      description: 'descr',
    });
    await flushPromises();

    expect(lessonService.update).toHaveBeenCalledWith({
      id: 1,
      title: 'newtitle',
      description: 'descr',
    });
  });

  it('should added additional media', async () => {
    expect.assertions(1);
    wrapper.vm.additionalMediaAdded({
      id: 1,
      title: 'newtitle',
      description: 'descr',
    });
    await flushPromises();

    expect(wrapper.vm.medias[0].id).toBe(1);
  });

  it('should remove additional media', async () => {
    expect.assertions(1);
    jest.spyOn(mediaService, 'getAll').mockResolvedValue({ data: { id: 1 } });

    jest.spyOn(courseService, 'getCourseContent').mockResolvedValue({
      data: {
        id: 1,
        lesson: {
          id: 1,
          title: '',
          description: '',
          available: false,
          section_id: 1,
          media: null,
          contents: [{ id: 1 }],
        },
      },
    });

    wrapper = mountWrapper(localVue, stubs, store, {
      type: 'file',
      mediaTypes: [
        {
          id: 'file',
          title: 'title',
          icon: 'book-alt',
          active: false,
          supportedFiles: [],
        },
      ],
    });

    wrapper.vm.additionalMediaRemoved({
      id: 1,
      title: 'newtitle',
      description: 'descr',
    });

    await flushPromises();

    expect(wrapper.vm.medias).toHaveLength(0);
  });

  it('should move item on additional media', async () => {
    expect.assertions(1);
    jest.spyOn(mediaService, 'getAll').mockResolvedValue({
      data: [{ id: 1 }, { id: 2 }],
    });

    jest.spyOn(courseService, 'getCourseContent').mockResolvedValue({
      data: {
        id: 1,
        lesson: {
          id: 1,
          title: '',
          description: '',
          available: false,
          section_id: 1,
          media: null,
          contents: [{ id: 1 }],
        },
      },
    });

    wrapper = mountWrapper(localVue, stubs, store, {
      type: 'file',
      mediaTypes: [
        {
          id: 'file',
          title: 'title',
          icon: 'book-alt',
          active: false,
          supportedFiles: [],
        },
      ],
    });

    wrapper.vm.additionalMediaMoved([{ id: 2 }, { id: 1 }]);

    await flushPromises();

    expect(wrapper.vm.medias.map(({ id }) => id)).toStrictEqual([2, 1]);
  });

  describe('additionalMediaChanged', () => {
    it('should change additional media - success', async () => {
      expect.hasAssertions();
      jest.spyOn(mediaService, 'update');

      await wrapper.vm.additionalMediaChanged({});

      expect(ToastHelper.successMessage).toHaveBeenCalled();
    });

    it('should change additional media - error', async () => {
      expect.hasAssertions();
      jest.spyOn(mediaService, 'update').mockRejectedValue();

      await wrapper.vm.additionalMediaChanged({});

      expect(ToastHelper.dangerMessage).toHaveBeenCalled();
    });
  });

  it('should set computed product', () => {
    expect(wrapper.vm.product).toStrictEqual({ id: 1, title: 'My Title', niche: null, subtitle: null });
  });

  it('should set computed selectedSchool', () => {
    expect(wrapper.vm.selectedSchool).toStrictEqual({});
  });
});
