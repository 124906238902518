<template>
  <section class="bg-white p-4 mb-5 rounded-sm">
    <h5 class="font-weight-bold mb-2">
      {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.details.title`) }}
    </h5>

    <p class="font-size-sm p-0 mb-3">
      {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.details.subtitle`) }}
    </p>

    <hs-group class="mb-4">
      <label class="font-weight-bold">
        {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.details.name-group.label`) }}
      </label>

      <hs-input
        :placeholder="
          $t(`sparkmembers.contents.views.editor.components.${type}.components.details.name-group.input-placeholder`)
        "
        v-model="title"
        :state="!$v.title.$error ? null : false"
        @blur="$v.title.$touch()"
      >
      </hs-input>
    </hs-group>

    <hs-group class="mb-5">
      <label for="description-editor" class="font-weight-bold">
        {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.details.description-group.label`) }}
      </label>

      <hs-text-editor
        id="description-editor"
        :initialContent="initialDescription || ``"
        :placeholder="
          $t(
            `sparkmembers.contents.views.editor.components.${type}.components.details.description-group.input-placeholder`
          )
        "
        @onUpdate="descriptionUpdated"
      />
    </hs-group>

    <div class="d-flex justify-content-end">
      <hs-button variant="primary" :disabled="$v.$invalid" @click="onSave">{{
        $t(`sparkmembers.contents.views.editor.components.${type}.components.details.save-button-label`)
      }}</hs-button>
    </div>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Details',
  props: {
    type: { type: String },
    initialTitle: {
      type: String,
    },
    initialDescription: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: this.initialTitle,
      description: this.initialDescription || '',
    };
  },
  methods: {
    descriptionUpdated(e) {
      this.description = e.getHTML();
      if (this.description === '<p></p>') this.description = '';
    },
    onSave() {
      this.$emit('save', { title: this.title, description: this.description });
    },
  },
  validations: {
    title: {
      required,
    },
  },
};
</script>
