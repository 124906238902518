<template>
  <div class="thumbnail" :class="isSidebar ? ['flex-column', 'isSidebar'] : ''">
    <div v-if="isLoading" class="thumbnail-frame">
      <hs-loading />
    </div>
    <div v-else-if="!Boolean(source)" class="thumbnail-frame" @click="$refs.file.click()">
      <hs-icon icon="image" :size="16" />
      <p>Arraste a imagem ou <span>clique aqui</span></p>
    </div>
    <div v-else>
      <div class="thumbnail-frame" @click="$refs.file.click()">
        <button
          id="thumbnail-remove"
          class="position-absolute thumbnail__remove border-0 p-0 bg-transparent"
          @click="removeThumbnail"
        >
          <hs-icon icon="trash-alt" class="rounded-circle p-2 bg-white text-danger" />
        </button>
        <b-popover triggers="hover" target="thumbnail-remove" custom-class="thumbnail-remove-popover">
          Excluir capa
        </b-popover>
        <img :src="source" />
      </div>
    </div>
    <p
      v-if="!isLoading && showMessage"
      :class="isSidebar ? 'mt-2' : 'ml-3'"
      style="text-align: left; max-width: 250px;"
    >
      Formatos: .jpeg, .jpg ou .png, dimensões ideais 1280 x 720px e 1MB.
    </p>
    <input
      v-if="!isLoading && !Boolean(source)"
      type="file"
      ref="file"
      accept="image/jpg, image/jpeg, image/png"
      style="display: none"
      @change="onSelectedImage"
    />
  </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'Thumbnail',
  components: {
    'hs-loading': Loading,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    source: {},
    showMessage: {
      type: Boolean,
      default: true,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeThumbnail(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('remove-image');
    },
    onSelectedImage(e) {
      this.loadImageToCrop(e.target.files[0]);
    },
    loadImageToCrop(file) {
      const reader = new FileReader();
      reader.onload = e => {
        const blob = new Blob([file], { type: file.type });
        this.$emit('selected-image', { blob, img: e.target?.result });
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss">
.thumbnail {
  display: flex;

  .thumbnail-frame {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 215px;
    height: 120px;
    border: 1px dashed #7427f1;
    border-radius: 4px;

    p {
      padding: 0 35px;
      margin: 0;
    }
  }

  &__remove {
    right: 6px;
    bottom: 6px;
  }

  p {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;

    color: #6f6f6f;
  }

  span {
    color: #7427f1;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  &.isSidebar {
    .thumbnail-frame {
      width: auto;
    }
  }
}
.thumbnail-remove-popover {
  .arrow:after {
    border-left-color: #3f3f3f;
    border-right-color: #3f3f3f;
  }

  .popover-body {
    color: white !important;
    background-color: #3f3f3f;
    font-size: 12px;
  }
}

@media screen and (min-width: $screen-xl) {
  .thumbnail {
    &.isSidebar {
      .thumbnail-frame {
        height: 170px;
      }
    }
  }
}
</style>
