<template>
  <div class="side-icon-btn d-flex align-items-center" v-on="$listeners">
    <i class="fal fa-arrow-left mx-2" />{{ $t('sparkmembers.contents.views.editor.components.back-button.label') }}
  </div>
</template>

<script>
export default {
  name: 'BackButton',
};
</script>
<style lang="scss" scoped>
.side-icon-btn {
  cursor: pointer;
}
</style>
