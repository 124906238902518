<template>
  <div class="d-flex flex-column align-items-center">
    <h5 class="font-weight-bold align-self-center">{{ text }}</h5>

    <div class="d-flex flex-column flex-md-row mt-4 align-items-center">
      <div class="side-icon-btn d-flex align-items-center" @click="$emit('change')">
        <hs-icon icon="exchange" />
        <span class="ml-1">
          {{ $t('sparkmembers.contents.views.editor.components.set.change-button-label') }}
        </span>
      </div>

      <div class="side-icon-btn d-flex align-items-center mt-3 mt-md-0 ml-md-4" @click="$emit('view')">
        <hs-icon icon="eye" />
        <span class="ml-1">
          {{ $t('sparkmembers.contents.views.editor.components.set.view-button-label') }}
        </span>
      </div>
    </div>

    <slot name="extra" />
  </div>
</template>

<script>
import debug from 'debug';

export const AvailableSetTypes = (type, translate) => ({
  Document: {
    icon: 'book-alt',
    text: translate(`sparkmembers.contents.views.editor.components.set.medias.${type}.label`),
  },
  Text: {
    icon: 'file-word',
    text: translate('sparkmembers.contents.views.editor.components.set.medias.text.label'),
  },
  Image: {
    icon: 'image',
    text: translate('sparkmembers.contents.views.editor.components.set.medias.image.label'),
  },
  Audio: {
    icon: 'microphone',
    text: translate('sparkmembers.contents.views.editor.components.set.medias.audio.label'),
  },
  Youtube: {
    icon: 'play-circle',
    text: translate('sparkmembers.contents.views.editor.components.set.medias.youtube.label'),
  },
  Vimeo: {
    icon: 'video',
    text: translate('sparkmembers.contents.views.editor.components.set.medias.vimeo.label'),
  },
  SparkVideos: {
    icon: 'file-video',
    text: translate('sparkmembers.contents.views.editor.components.set.medias.video.label'),
  },
});

export default {
  name: 'Set',
  props: {
    type: {
      type: String,
    },
    media: {
      type: Object,
    },
  },
  data() {
    let decoratedMedia = JSON.parse(JSON.stringify(this.media));
    const type = decoratedMedia.type === 'Video' ? decoratedMedia.host : decoratedMedia.type;

    const data = AvailableSetTypes(this.type, key => this.$t(key))[type];
    if (!data) {
      const logging = debug('hs:media_set');
      logging(`type ${type} is not available`);
    }

    return data;
  },
};
</script>

<style lang="scss" scoped>
.side-icon-btn {
  cursor: pointer;
}
</style>
