import mediaService from '@/sparkmembers/services/media';
import lessonService from '@/sparkmembers/services/lesson';
import uploadService from '@/services/upload';
import ToastHelper from '@/shared/helpers/toast';

// when changing, it must perform immediately,
// otherwise store the information and wait
// for the save button.
export const CHANGING_THUMBNAIL = 'changing';
export const UPLOADING_THUMBNAIL = 'uploading';

export default {
  data() {
    return {
      thumbnailMode: null,
      thumbnailImg: null,
      uploadImg: null,
      isThumbnailLoading: false,
    };
  },
  computed: {
    thumbnail() {
      return this.coverImage ? this.coverImage.s3_file_url : this.uploadImg;
    },
  },
  methods: {
    currentThumbnailMode(media) {
      return !media ? UPLOADING_THUMBNAIL : CHANGING_THUMBNAIL;
    },
    onlyChangeThumbnail({ blob, img }) {
      this.uploadImg = img;
      this.thumbnailImg = blob;
    },
    cleanThumbnail() {
      this.uploadImg = null;
      this.thumbnailImg = null;
      this.coverImage = null;
    },
    makeMediaObject(title, image) {
      return {
        type: 'Image',
        title,
        s3_file_url: image,
      };
    },
    async selectedThumbnail(mode, { blob, img }) {
      this.thumbnailMode = mode;
      this.uploadImg = img;
      this.thumbnailImg = blob;
      this.onSaveThumbnail(blob);
    },
    async changeLessonsThumbnail(image) {
      const mediaObject = this.makeMediaObject(this.media.title, image);

      const media = (await mediaService.create({
        media: mediaObject,
      })).data;

      const lessonData = {
        id: this.lesson.id,
        type: 'ContentLesson',
        cover_image_id: media.id,
      };

      await lessonService.update(lessonData);

      ToastHelper.successMessage('Capa atualizada.');

      return media;
    },
    async uploadFileToAwsS3(file) {
      const { data } = await uploadService.getAwsKeys(file.type, this.selectedSchool.id);
      const imgURL = await uploadService.upload(data.url, data.fields, file);

      return imgURL;
    },
    async saveOnlyMedia(image, title = null) {
      // NOTE: this seems to always exist
      // because it's part of the lesson's
      // content.
      const defaultTitle = title || this.content.lesson.title;
      const mediaObject = this.makeMediaObject(this.media?.title || this.activity?.title || defaultTitle, image);

      const media = (await mediaService.create({
        media: mediaObject,
      })).data;

      return media;
    },
    async onSaveThumbnail(file) {
      try {
        this.isThumbnailLoading = true;
        const uploadedImage = await this.uploadFileToAwsS3(file);
        let action = this.thumbnailMode == CHANGING_THUMBNAIL ? this.changeLessonsThumbnail : this.saveOnlyMedia;

        this.coverImage = await action(uploadedImage);
      } catch (e) {
        ToastHelper.dangerMessage('Falha ao trocar a imagem da capa.');
      } finally {
        this.isThumbnailLoading = false;
      }
    },
    async onCreateLiveThumbnail(file, title) {
      try {
        this.isThumbnailLoading = true;
        const uploadedImage = await this.uploadFileToAwsS3(file);
        let action = this.thumbnailMode == CHANGING_THUMBNAIL ? this.changeLessonsThumbnail : this.saveOnlyMedia;

        this.coverImage = await action(uploadedImage, title);
        return this.coverImage;
      } catch (e) {
        ToastHelper.dangerMessage('Falha ao trocar a imagem da capa.');
      } finally {
        this.isThumbnailLoading = false;
      }
    },
    async removeThumbnail(media) {
      try {
        this.isThumbnailLoading = true;

        if (this.currentThumbnailMode(media) == CHANGING_THUMBNAIL) {
          const type = this.activity ? 'ExamLesson' : 'ContentLesson';

          const lessonData = {
            type,
            id: this.lesson.id,
            cover_image_id: null,
          };

          await lessonService.update(lessonData);

          ToastHelper.successMessage('Capa foi removida');
        }

        this.cleanThumbnail();
        this.coverImage = null;
      } catch (e) {
        ToastHelper.dangerMessage('Falha ao remover a imagem da capa');
      } finally {
        this.isThumbnailLoading = false;
      }
    },
  },
};
