<template>
  <div v-if="isLoading" class="d-flex flex-column justify-content-center">
    <hs-loading />
  </div>
  <section v-else>
    <hsPageHeader
      :title="lesson.title"
      :back-text="$t(`sparkmembers.coupons.views.list.components.page-header.back-button-label`)"
      back-route="ProductDashboard"
    >
      <template slot="actions">
        <a class="text-decoration-none text-dark" target="_blank" :href="showLesson">
          <hs-icon class="mr-1" variant="light" icon="eye" />
          {{
            $t(
              `sparkmembers.contents.views.editor.components.${type}.components.page-header.index.show-lesson-button-label`
            )
          }}
        </a>
      </template>
    </hsPageHeader>
    <section class="container-fluid">
      <div class="row mt-5 px-4">
        <div class="col-xl-9">
          <MainMedia :type="type" :types="mediaTypes" :media="media" @save="onMainMediaSave">
            <template #extra>
              <div class="mt-4 mb-2">
                <p class="d-inline font-weight-bold mb-1">
                  Adicionar uma imagem de capa
                  <hs-icon v-if="Boolean(media)" id="thumbnail-info" class="ml-2" icon="info-circle" :size="14" />
                </p>
                <p v-if="!Boolean(media)">Personalize a capa de cada uma das suas aulas.</p>

                <b-popover
                  v-if="Boolean(media)"
                  triggers="hover"
                  target="thumbnail-info"
                  custom-class="thumbnail-info-popover"
                >
                  Formatos: .jpeg, .jpg ou .png, dimensões ideais 1280 x 720px e 1MB.
                </b-popover>
              </div>

              <Thumbnail
                :isLoading="isThumbnailLoading"
                :source="thumbnail"
                :showMessage="!Boolean(media)"
                @selected-image="onSelectedThumbnail"
                @remove-image="removeThumbnail(media)"
              />
            </template>
          </MainMedia>
          <Details
            data-track="lesson details"
            :type="type"
            :initialTitle="lesson.title"
            :initialDescription="lesson.description"
            @save="onDetailsSave"
          />
          <AdditionalMedias
            :type="type"
            :additionalMedias="additionalMedias"
            @add="additionalMediaAdded"
            @change="additionalMediaChanged"
            @remove="additionalMediaRemoved"
            data-track="lesson extra content"
            @move="additionalMediaMoved"
            @premium="onPremiumFeature"
          />
          <Automations
            id="automations"
            :type="type"
            data-track="lesson automation"
            :automations="automations"
            @save="automationSaved"
            @change="automationChanged"
            @remove="automationRemoved"
            @premium="onPremiumFeature"
          />
        </div>
        <div class="col-xl-3">
          <CommentsCard v-model="commentsActivated" :type="type" @input="commentsActivationChanged" />
        </div>
      </div>
    </section>
    <hsPremiumModal id="premium-modal" @click="requestPremiumFeature" />
  </section>
</template>

<script>
import debug from 'debug';
import { mapActions, mapState, mapGetters } from 'vuex';
import { hsLoading, hsPremiumModal } from '@/components';
import ThumbnailMixin, { CHANGING_THUMBNAIL, UPLOADING_THUMBNAIL } from '@/mixins/ThumbnailMixin';
import lessonService from '@/sparkmembers/services/lesson';
import courseService from '@/sparkmembers/services/course';
import mediaService from '@/sparkmembers/services/media';
import analyticsService from '@/services/analytics';
import hsPageHeader from '@/components/_structures/PageHeader';
import toastHelper from '@/shared/helpers/toast';
import AdditionalMedias from './AdditionalMedias';
import Automations from './Automations.vue';
import MainMedia from './MainMedia';
import Details from './Details';
import CommentsCard from './CommentsCard';
import AutomationMixin from '@/sparkmembers/views/Contents/mixins/AutomationMixin';
import Thumbnail from '@/components/Thumbnail';
import { getAdminViewSsoUrl } from '@/shared/helpers/general';

const logging = debug('hs:product_content');

export default {
  name: 'ProductWithFile',
  mixins: [AutomationMixin, ThumbnailMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    mediaTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      content: {},
      lesson: { title: '', description: '' },
      media: null,
      medias: [],
      commentsActivated: false,
      coverImage: null,
    };
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('school', ['selectedSchool']),
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
      loggedUser: state => state.auth.loggedUser,
      selectedSchool: state => state.school.selectedSchool,
    }),
    additionalMedias() {
      return this.media ? this.medias.filter(m => m.id !== this.media.id) : this.medias;
    },
    showLesson() {
      return getAdminViewSsoUrl({
        domain: this.getDomain,
        user: this.loggedUser,
        redirectTo: `/admin_view/courses/${this.course.id}/course_contents/${this.$route.params.contentId}`,
      });
    },
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    onSelectedThumbnail(img) {
      this.selectedThumbnail(this.currentThumbnailMode(this.media), img);
    },
    async loadContent() {
      this.isLoading = true;

      try {
        const { data: content } = await courseService.getCourseContent(this.$route.params.contentId);
        this.content = content;
        logging('loaded content: %o', content);
        const { id, title, description, available, section_id, media, contents, cover_image } = content.lesson;
        this.lesson = { id, title, description, available, section_id, contents };

        this.commentsActivated = section_id !== null;
        this.coverImage = cover_image;

        if (media) this.media = (await mediaService.get(media.id)).data;

        if (contents) {
          const content_ids = contents.map(c => c.content.id);
          const medias = (await mediaService.getAll(content_ids)).map(r => r.data);
          this.medias = content_ids.map(cid => medias.find(m => m.id === cid));
        }
        this.loadAutomationsFromSelectedLesson();
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${this.type}.index.toast-messages.content-loading-error`
          )
        );
        this.$router.push({ name: 'ProductDashboard' });
      } finally {
        this.isLoading = false;
      }
    },
    async onMainMediaSave(attrs) {
      try {
        this.isLoading = true;

        attrs.title = this.lesson.title;

        const newMedia = (await mediaService.create({ media: { ...attrs } })).data;

        const content_ids = this.medias.map(m => m.id);

        if (this.media) {
          content_ids[0] = newMedia.id;
          this.medias[0] = newMedia;
        } else {
          content_ids.unshift(newMedia.id);
          this.medias.unshift(newMedia);
        }

        const lessonData = {
          id: this.lesson.id,
          media_id: newMedia.id,
          content_ids,
        };

        if (this.coverImage) {
          lessonData.cover_image_id = this.coverImage.id;
        }

        await lessonService.update(lessonData);

        this.media = newMedia;

        this.cleanThumbnail();

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.main-media-creation-success`
          )
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${this.type}.index.toast-messages.main-media-creation-error`
          )
        );
      } finally {
        this.isLoading = false;
      }
    },
    async onDetailsSave(attrs) {
      this.isLoading = true;
      try {
        await lessonService.update({
          id: this.lesson.id,
          ...attrs,
        });

        this.lesson.title = attrs.title;
        this.lesson.description = attrs.description;

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${this.type}.index.toast-messages.details-update-success`
          )
        );

        this.isLoading = false;
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${this.type}.index.toast-messages.details-update-error`
          )
        );

        this.isLoading = false;
      }
    },
    async additionalMediaAdded(attrs) {
      this.isLoading = true;

      try {
        const newAdditionalMedia = (await mediaService.create({ media: { ...attrs } })).data;
        const content_ids = this.medias.map(m => m.id);

        content_ids.push(newAdditionalMedia.id);

        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-add.success`
          )
        );

        this.medias.push(newAdditionalMedia);
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${this.type}.index.toast-messages.additional-media-add.error`
          )
        );
      }

      this.isLoading = false;
    },
    async additionalMediaChanged(attrs) {
      try {
        await mediaService.update(attrs);

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-update.success`
          )
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-update.error`
          )
        );
      }
    },
    async additionalMediaRemoved(id) {
      this.isLoading = true;

      try {
        let content_ids = this.medias.map(m => m.id);
        content_ids = content_ids.filter(cid => cid !== id);

        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-remove.success`
          )
        );

        this.medias = this.medias.filter(m => m.id !== id);
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-remove.error`
          )
        );
      }

      this.isLoading = false;
    },
    async additionalMediaMoved(additionalMedias) {
      const content_ids = additionalMedias.map(am => am.id);
      if (this.media) content_ids.unshift(this.media.id);

      try {
        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        this.medias = [...additionalMedias];
        if (this.media) this.medias.unshift(this.media);

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-move.success`
          )
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.index.toast-messages.additional-media-move.error`
          )
        );
      }
    },
    async commentsActivationChanged() {
      if (this.commentsActivated) {
        const { section } = await lessonService.addCommentsInLesson({
          title: this.lesson.title,
          description: this.lesson.description,
          school_product_id: this.product.id,
          lesson_id: this.lesson.id,
        });
        this.lesson.section_id = section.id;
      } else this.lesson.section_id = null;

      await lessonService.update({
        id: this.lesson.id,
        section_id: this.lesson.section_id,
      });
    },
    async onPremiumFeature(feature) {
      this.premiumFeatureLocation = `lesson ${feature.toLowerCase()}`;
      this.$bvModal.show('premium-modal');
      analyticsService.track({
        event: 'Paywall displayed',
        props: {
          location: this.premiumFeatureLocation,
        },
      });
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = 'true';
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },
    requestPremiumFeature() {
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal');
    },
  },
  components: {
    hsLoading,
    hsPremiumModal,
    hsPageHeader,
    MainMedia,
    Details,
    AdditionalMedias,
    Automations,
    CommentsCard,
    Thumbnail,
  },
  created() {
    this.loadContent();
  },
};
</script>

<style lang="scss">
.thumbnail-info-popover {
  .arrow:after {
    border-left-color: #3f3f3f;
    border-right-color: #3f3f3f;
  }

  .popover-body {
    color: white !important;
    background-color: #3f3f3f;
    font-size: 12px;
  }
}
</style>
