<template>
  <div class="bg-white mb-5 px-md-3 py-md-4">
    <Set
      v-if="mode === 'set' && media"
      :type="selectedType.id"
      :media="media"
      @change="mode = 'type-selection'"
      @view="mode = 'view'"
    >
      <template #extra>
        <slot name="extra" />
      </template>
    </Set>
    <div v-if="mode === 'view'">
      <BackButton @click="mode = 'set'" />
      <MediaViewer class="mt-3" :media="media" :type="type" />
    </div>
    <div v-if="mode === 'type-selection'">
      <BackButton v-if="media" @click="mode = 'set'" />
      <TypeSelector
        class="mt-3"
        :title="$t(`sparkmembers.contents.views.editor.components.${selectorLabel}.components.main-media.title`)"
        :types="types"
        @select="typeSelected"
      />
    </div>
    <MediaInput
      v-if="mode === 'input'"
      :configuration="selectedType"
      :type="selectedType.id"
      :hideNameInput="true"
      @save="$emit('save', $event)"
      @cancel="mode = 'type-selection'"
    >
      <template #extra>
        <slot name="extra" />
      </template>
    </MediaInput>
  </div>
</template>

<script>
import _ from 'lodash';
import MediaViewer from '@/components/MediaViewer';
import MediaInput from '@/components/MediaInput';
import TypeSelector from './TypeSelector';
import BackButton from './BackButton';
import Set from './Set';
import { selectTypeByMedia } from '@/libs/media';

export default {
  name: 'MainMedia',
  props: {
    type: {
      type: String,
    },
    media: {
      type: Object,
    },
    types: {
      type: Array,
      required: true,
    },
  },
  data() {
    const { types } = this;
    const selectedType = types.length == 1 && types[0];
    return {
      mode: this.media ? 'set' : 'type-selection',
      selectedType: selectedType || selectTypeByMedia(this.media, types),
    };
  },
  components: {
    MediaViewer,
    MediaInput,
    TypeSelector,
    BackButton,
    Set,
  },
  computed: {
    selectorLabel() {
      return this.type == 'additional' ? this.type : _.get(this.selectedType, 'id', this.type);
    },
  },
  methods: {
    typeSelected(type) {
      this.selectedType = type;
      this.mode = 'input';
    },
  },
};
</script>
