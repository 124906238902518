import { Optional } from '@/types/optional';
import { Media, MediaGroupOption, MediaOption } from '@/types/media';
import _ from 'lodash';

export const selectTypeByMedia = (media: Media, types: MediaGroupOption[]): Optional<MediaOption> => {
  if (!media) {
    return null;
  }

  let mediaType: string;

  if (media.type === 'Document') {
    mediaType = 'file';
  } else {
    mediaType = /video/i.test(media.type) ? media.host : media.type;
  }

  const id = new RegExp(`${mediaType.toLowerCase()}`, 'i');

  const findOnSubtype = (subtype: MediaOption): boolean => id.test(subtype.id as string);

  const found = _.reduce(
    types,
    (acc: Optional<MediaOption>, type: MediaGroupOption) =>
      (acc || _.find(type.subTypes || [], findOnSubtype)) as Optional<MediaOption>,
    null
  );

  if (!found) {
    throw new Error(`Media option not found for ${media.type} ${media.host || ''}`);
  }

  return found;
};
